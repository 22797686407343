import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = [ 'areas' ];

  connect() {
    // console.log(this.areasTarget)
    // new SlimSelect({
    //   select: this.areasTarget //'.new_area_adress_search'
    // });
  }

  areasTargetConnected(target) {
    var placeholderAttr = target.attributes['placeholder']
    var placeholderText = placeholderAttr ? placeholderAttr.nodeValue : 'Select Value'
    this.select = new SlimSelect({
      select: target, //'.new_area_adress_search'
      settings: {
        placeholderText: placeholderText,
      }
    });
  }


  searchTypeClicked(event) {
    event.preventDefault();
    const {currentTarget, params: {subtypes}} = event;
    var checkbox = currentTarget.querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked;

    if(checkbox.checked) {
      currentTarget.classList.add('selected');
    } else {
      currentTarget.classList.remove('selected');
    }
  }

  disconnect() {
    this.select.destroy()
  }
}

