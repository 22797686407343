import { Controller } from "@hotwired/stimulus";
import {tns} from 'tiny-slider';

export default class extends Controller {
  static targets = [ 'container', 'prev', 'next' ];

  connect() {
  }

  containerTargetConnected(target) {
    this.slider = tns({
      container: target, //'.recommendations',
      // items: 3,
      // slideBy: 'page',
      prevButton: this.prevTarget,
      nextButton: this.nextTarget,
      autoplay: true,
      speed: 600,
      nav: false,
      autoplayHoverPause: true,
      autoplayTimeout: 4000,
      autoplayButtonOutput: false,
      // gutter: 9,
      // "autoWidth": true,
      // center: true,
      responsive: {
        350: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3
        }
      },
      autoplayText: [
        "▶",
        "❚❚"
      ],
    });
  }

  disconnect() {
    try {
      this.slider.destroy()
    } catch {

    }

  }
}

