import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = [ "subTypesSearch", "subTypesSearchWrapper", "searchType", "minPrice", "maxPrice" ];

  getSelectPlaceholder(className) {
    var attr = document.getElementsByClassName(className)[0].attributes['placeholder']
    return attr ? attr.nodeValue : 'Select Value'
  }

  connect() {
    this.areaSearchSelect = new SlimSelect({
      select: '.new_area_adress_search',
      settings: {
        placeholderText: this.getSelectPlaceholder('new_area_adress_search')
      }
    });

    this.subTypeSelect = new SlimSelect({
      select: '.sub_types_search',
      settings: {
        placeholderText: this.getSelectPlaceholder('sub_types_search')
      }
    });

    this.keywordsSelect = new SlimSelect({
      select: '.new_keywords_search',
      settings: {
        placeholderText: this.getSelectPlaceholder('new_keywords_search')
      }
    });
    this.minPriceChange();
    this.maxPriceChange();
  }

  newDevClicked(event) {
    event.preventDefault();
    const {currentTarget, params: {subtypes}} = event;
    var checkbox = currentTarget.querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked;

    if(checkbox.checked) {
      currentTarget.classList.add('selected');
    } else {
      currentTarget.classList.remove('selected');
    }
  }

  searchTypeClicked(event) {
    event.preventDefault();
    const {currentTarget, params: {subtypes}} = event;
    var checkbox = currentTarget.querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked;

    if(!subtypes) return

    const {Type: name, SubType: subTypes} = subtypes;

    if(checkbox.checked) {
      var optgroup = document.createElement("optgroup");
      optgroup.setAttribute("label", name);
      for(var i = 0; i < subTypes.length; i++) {
        var option = document.createElement("option");
        option.innerHTML = subTypes[i].Type;
        option.value = subTypes[i].OptionValue;
        optgroup.appendChild(option);
      }
      this.subTypesSearchTarget.appendChild(optgroup);
      currentTarget.classList.add('selected');
      this.subTypesSearchWrapperTarget.style.display = 'block';
    } else {
      var children = this.subTypesSearchTarget.childNodes;
      for(var i = 0; i < children.length; i++) {
        if(children[i] && children[i].label === name) {
          this.subTypesSearchTarget.removeChild(children[i]);
          break;
        }
      }

      if(!this.subTypesSearchTarget.hasChildNodes()) {
        this.subTypesSearchWrapperTarget.style.display = 'none';
      }
      currentTarget.classList.remove('selected');
    }
  }

  minPriceChange() {
    const value = parseInt(this.minPriceTarget.value, 10);
    const maxVal = parseInt(this.maxPriceTarget.value, 10);
    this.maxPriceTarget.querySelectorAll('option').forEach(option => {
      option.disabled = option.value && parseInt(option.value, 10) < value;
    });
    if(maxVal && maxVal < value) {
      this.maxPriceTarget.value = value;
    }
  }

  maxPriceChange(event) {
    const value = parseInt(this.maxPriceTarget.value, 10);
    const minVal = parseInt(this.minPriceTarget.value, 10);
    this.minPriceTarget.querySelectorAll('option').forEach(option => {
      option.disabled = option.value && parseInt(option.value, 10) > value;
    });
    if(minVal && minVal > value) {
      this.minPriceTarget.value = value;
    }
  }

  disconnect() {
    this.areaSearchSelect.destroy();
    this.subTypeSelect.destroy();
    this.keywordsSelect.destroy();
  }
}

