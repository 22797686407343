import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "alert" ];

  connect() {

  }

  alertTargetConnected(target) {
    var alert = new bootstrap.Alert(target);
    setTimeout(function() {
      alert.close();
    }, 10000);
  }

  // removeElement() {
  //   document.getElementById('alerts').remove();
  // }
}
