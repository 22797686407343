import { Controller } from "@hotwired/stimulus"
import {Map, View} from 'ol'
import {Vector, Tile} from 'ol/layer'
import {GeoJSON} from 'ol/format'
import {bbox} from 'ol/loadingstrategy'
import {Style, Fill, Stroke} from 'ol/style'
import {OSM, Vector as SourceVector} from 'ol/source';
export default class extends Controller {
  static targets = [ "canvas" ];
  static values = {
    url: String
  }

  connect() {
    // console.log('map connected')
  }

  canvasTargetConnected(target) {
    var olViewPorts = this.canvasTarget.getElementsByClassName('ol-viewport')
    for (let i = 0; i < olViewPorts.length; i++) {
      const oldMap = olViewPorts[i];
      this.canvasTarget.removeChild(oldMap)
    }

    if(olViewPorts && olViewPorts.length > 0) {
      div.parentNode.removeChild(div);
    }

    this.source = new SourceVector({
      url: this.urlValue,
      format: new GeoJSON(),
      strategy: bbox
    });

    this.style = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: '#A81318',
        width: 1.5,
      })
    });

    this.vectorLayer = new Vector({
      source: this.source,
      style: this.style,
    });

    this.view = new View({
      center: [0, 0],
      zoom: 1,
    });

    this.map = new Map({
      layers: [
        new Tile({
          source: new OSM(),
        }),
        this.vectorLayer,
      ],
      target: target,
      view: this.view,
    });

    this.source.once('change', (evt) => {
      if (this.source.getState() === 'ready') {
        // now the source is fully loaded
        if (this.vectorLayer.getSource().getFeatures().length > 0) {
          this.map.getView().fit(this.source.getExtent(), this.map.getSize());
        }
      }
    });
  }

  disconnect() {
    // debugger
    // this.map.destroy()
  }

}

