import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ "button" ];

  connect() {
    this.scrollTrigger = 100
  }

  buttonTargetConnected(target) {
    this.verticalScroll(target);
  }

  scrollToTop(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
  }

  verticalScroll(target) {
    var scrollTop = window.pageYOffset;
    if (scrollTop > this.scrollTrigger) {
      this.buttonTarget.classList.add('show');
    } else {
      this.buttonTarget.classList.remove('show');
    }
  }
}
