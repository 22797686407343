import { Controller } from "@hotwired/stimulus"
import {Carousel} from "bootstrap"

export default class extends Controller {
  connect() {
    // console.log('connected', this.context.element);
    this.carousel = new Carousel(this.context.element, {
      interval: 4000,
      wrap: false
    });
  }

  disconnect() {
    this.carousel.dispose()
  }
}
