import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  /**
   * Sets a cookie with the given name, value and expiration date.
   * @param {string} cname - The name of the cookie.
   * @param {string} cvalue - The value of the cookie.
   * @param {number} exdays - The number of days until the cookie expires.
   */
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  /**
   * Gets the value of a cookie with the given name.
   * @param {string} cname - The name of the cookie.
   * @returns {string} The value of the cookie.
   */
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /**
   * Removes a cookie with the given name.
   * @param {string} cname - The name of the cookie.
   */
  removeCookie(cname) {
    setCookie(cname, "", -1);
  }
}