// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import Rails from '@rails/ujs'
Rails.start()

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// import {tns} from 'tiny-slider';

// var myCarousel = document.querySelector('#recipeCarousel')
// var carousel = new bootstrap.Carousel(myCarousel)

// var recommendation = document.querySelector('.recommendations')
// if(recommendation) {
//   var prev = document.querySelector('button[data-controls="prev"]')
//   console.log('prev', prev)
//   var next = document.querySelector('button[data-controls="next"]')
//   console.log('next', prev)

//   var slider = tns({
//     container: '.recommendations',
//     // items: 3,
//     // slideBy: 'page',
//     prevButton: prev,
//     nextButton: next,
//     autoplay: true,
//     speed: 400,
//     nav: false,
//     autoplayHoverPause: true,
//     autoplayTimeout: 2000,
//     autoplayButtonOutput: false,
//     // gutter: 9,
//     // "autoWidth": true,
//     // center: true,
//     responsive: {
//       350: {
//         items: 1,
//       },
//       768: {
//         items: 2,
//       },
//       992: {
//         items: 3
//       }
//     },
//     autoplayText: [
//       "▶",
//       "❚❚"
//     ],
//   });
// }


// console.log('fasdfasdfasdf')
// new SlimSelect({
//   select: '.new_area_adress_search'
// })

// new SlimSelect({
//   select: '.sub_types_search'
// })

// new SlimSelect({
//   select: '.new_keywords_search'
// })


