import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [];

  connect() {
    this.bsModal = new bootstrap.Modal(this.element, {});
    this.bsModal.show();
    this.element.addEventListener('hidden.bs.modal', this.onHide);
  }

  onHide(event) {
    this.remove();
  }

  disconnect() {

  }
}

