import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ "save", "modal", "searchName" ];
  static values = { url: String}

  connect() {
    console.log('werwad')
  }

  showModal(e) {
    e.preventDefault()
    // console.log(this.saveTarget)
    this.bsModal = new bootstrap.Modal(this.modalTarget, {});
    this.bsModal.show();
    // this.modalTarget.addEventListener('hidden.bs.modal', this.onHide);
  }

  beforeSend(e) {
    if(e.target.search_name.value.length === 0) {
      // e.preventDefault()

    }
  }

  saveSuccess(e) {
    const [data, status, xhr] = e.detail;
    this.bsModal.hide()
    this.saveTarget.style.display = 'none'
    const {name} = JSON.parse(data)
    this.searchNameTarget.textContent = name
    this.searchNameTarget.style.display = ''
  }

  saveError(e) {
    const [data, status, xhr] = e.detail;
    const errors = JSON.parse(data)
    for (const field in errors) {
      const errorMsgs = errors[field];
      const input = e.target.querySelector(`#search_${field}`)
      input.classList.add('is-invalid')

      const wrapper = e.target.querySelector(`div.search_${field}`)
      wrapper.classList.add('form-group-invalid')
      wrapper.innerHTML += `<div class="invalid-feedback">${errorMsgs.join(', ')}</div>`
    }
  }

  saveTargetConnected(target) {
    console.log('save is connected')
  }
}
